<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('general.welcome') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('admin.auth.login.slogan') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
          >
            <b-form-group
              :label="$t('form.email.label')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="userData.email"
                type="email"
                class="form-control form-control-merge"
                :state="errors && errors.email ? false : null"
                name="login-email"
                :placeholder="$t('form.email.label')"
              />
              <small
                v-if="errors && errors.email"
                class="text-danger"
              >{{ errors.email[0] }}</small>
            </b-form-group>

            <b-form-group
              :label="$t('form.password.label')"
              label-for="login-password"
            >
              <b-form-input
                id="login-password"
                v-model="userData.password"
                type="password"
                class="form-control form-control-merge"
                :state="errors && errors.password ? false : null"
                name="login-password"
                :placeholder="$t('form.password.label')"
              />
              <small
                v-if="errors && errors.password"
                class="text-danger"
              >{{ errors.password[0] }}</small>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              variant="primary"
              block
              @click="login"
            >
              {{ $t('general.send') }}
            </b-button>
          </b-form>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      title: process.env.TITLE,
      userData: {
        login: null,
        password: null,
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    login() {
      useJwt.login({
        email: this.userData.email,
        password: this.userData.password,
      })
        .then(response => {
          const { data, token, localization } = response.data

          useJwt.setToken(token)
          // useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(data))
          localStorage.setItem('localization', JSON.stringify(localization))

          this.$i18n.locale = localization.admin_language
          this.$i18n.fallbackLocale = localization.language

          this.$ability.update(data.permissions)

          this.$router.replace(getHomeRouteForLoggedInUser())
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${this.$t('general.welcome')} ${data.name}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: this.$t('admin.auth.login.successfully_logged'),
                },
              })
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
